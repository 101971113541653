import React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import PropTypes from "prop-types";
import { capitalizeFirstLetter } from "util/formatTimeAndDate";

export default function SettlementDrawer({ open, onClose, settlement, downloadInvoice, merchant }) {
    if (!settlement) return null;

    const {
        type,
        transaction_date,
        txIndex,
        amount,
        netVolume,
        feePercentage,
        netFees,
        gst,
        netGst,
        bankFees,
        netBankFees,
        usdtRate,
        usdt,
        ref_no,
    } = settlement;

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: {
                        xs: "90%", // 100% width for extra-small screens (phones)
                        sm: "50%",  // 50% width for small screens and above
                    },
                    padding: "16px",
                    overflowY: "auto",
                    backgroundColor: "#f9f9f9",
                },
            }}
        >
            <MDBox>
                {/* Settlement Type Header */}
                <MDBox textAlign="center" mb={2}>
                    <MDTypography variant="subtitle1" fontWeight="bold" color="info">
                        {type === "rolling_reserve" ? "Rolling Reserve" : capitalizeFirstLetter(type) || "Settlement Report"}
                    </MDTypography>
                </MDBox>

                {/* Header with Close Icon */}
                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <MDTypography variant="h6" fontWeight="bold" sx={{ color: "#333", fontSize: "16px" }}>
                        Transaction Settlement Report - {new Date(transaction_date).toLocaleDateString()}
                    </MDTypography>
                    <IconButton onClick={onClose} size="small">
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </MDBox>
                <Divider sx={{ mb: 2 }} />

                {/* Merchant and Settlement Info */}
                <MDBox>
                    <MDTypography variant="body2" fontWeight="medium" sx={{ mb: 1, fontSize: "12px" }}>
                        <strong>Merchant:</strong> {capitalizeFirstLetter(merchant) || ''}
                    </MDTypography>
                    <MDTypography variant="body2" fontWeight="medium" sx={{ mb: 1, fontSize: "12px" }}>
                        <strong>Settlement Date:</strong> {new Date(transaction_date).toLocaleDateString()}
                    </MDTypography>
                    <MDTypography variant="body2" fontWeight="medium" sx={{ mb: 2, fontSize: "12px" }}>
                        <strong>Settlement No.:</strong> {txIndex + 1}
                    </MDTypography>
                </MDBox>

                {/* Particulars Table */}
                <MDBox>
                    {/* Settlement Type Header */}
                    <MDTypography
                        variant="body1"
                        fontWeight="bold"
                        sx={{
                            color: (theme) => theme.palette.info.main,
                            mb: 2,
                            fontSize: (theme) => theme.typography.fontSize,
                        }}
                    >
                        Type of Report: {type === "rolling_reserve" ? "Rolling Reserve" : capitalizeFirstLetter(type)}
                    </MDTypography>

                    {/* Table Headers */}
                    <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={1}
                        px={2} // Padding for left and right
                    >
                        <MDTypography
                            sx={{
                                fontWeight: "medium",
                                fontSize: (theme) => theme.typography.fontSize,
                                flex: 1, // Stretch to left side
                                color: (theme) => theme.palette.text.primary,
                            }}
                        >
                            Particulars
                        </MDTypography>
                        <MDBox display="flex" justifyContent="space-between" flex={2}>
                            <MDTypography
                                sx={{
                                    width: "33%", // Consistent width for each column
                                    textAlign: "center",
                                    fontWeight: "medium",
                                    fontSize: (theme) => theme.typography.fontSize,
                                    color: (theme) => theme.palette.text.primary,
                                }}
                            >
                                Currency
                            </MDTypography>
                            <MDTypography
                                sx={{
                                    width: "33%", // Consistent width for each column
                                    textAlign: "center",
                                    fontWeight: "medium",
                                    fontSize: (theme) => theme.typography.fontSize,
                                    color: (theme) => theme.palette.text.primary,
                                }}
                            >
                                Rate (%)
                            </MDTypography>
                            <MDTypography
                                sx={{
                                    width: "33%", // Consistent width for each column
                                    textAlign: "right",
                                    fontWeight: "medium",
                                    fontSize: (theme) => theme.typography.fontSize,
                                    color: (theme) => theme.palette.text.primary,
                                }}
                            >
                                Amount
                            </MDTypography>
                        </MDBox>
                    </MDBox>

                    {/* Data Rows */}
                    <MDBox>
                        {[
                            // Example rows, replace with actual data
                            {
                                particular: "Total Amount",
                                currency: "INR",
                                rate: "-",
                                amount: amount?.toFixed(2),
                                visible: true, // Always visible
                            },
                            {
                                particular: "Platform Fee",
                                currency: "INR",
                                rate: feePercentage ? `${feePercentage}%` : "-",
                                amount: netFees?.toFixed(2),
                                visible: ["settlement", "refund", "chargeback"].includes(type), // Visible for settlement, refund, chargeback
                            },
                            {
                                particular: "Bank Fees",
                                currency: "INR",
                                rate: bankFees ? `${bankFees}%` : "-",
                                amount: netBankFees?.toFixed(2),
                                visible: type === "settlement", // Visible only for settlement
                            },
                            {
                                particular: "GST",
                                currency: "INR",
                                rate: gst ? `${gst}%` : "-",
                                amount: netGst?.toFixed(2),
                                visible: type === "settlement", // Visible only for settlement
                            },
                        ]
                            .filter((row) => row.visible) // Filter rows based on visibility
                            .map((row, index, filteredRows) => {
                                // Determine max index for borderBottom condition
                                const maxIndexForBorder =
                                    type === "settlement"
                                        ? filteredRows.length - 1 // All rows except the last
                                        : ["refund", "chargeback"].includes(type)
                                            ? 1
                                            : ["rolling_reserve", "misc"].includes(type)
                                                ? 0
                                                : -1;

                                return (
                                    <MDBox
                                        key={index}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        py={1}
                                        px={2} // Padding for left and right
                                        sx={{
                                            borderBottom: index < maxIndexForBorder ? "1px solid #ddd" : "none", // Apply conditional border
                                        }}
                                    >
                                        {/* Left: Particular */}
                                        <MDTypography
                                            sx={{
                                                fontSize: (theme) => theme.typography.fontSize,
                                                flex: 1, // Aligns to left
                                                color: (theme) => theme.palette.text.secondary,
                                            }}
                                        >
                                            {row.particular}
                                        </MDTypography>

                                        {/* Right: Currency, Rate, Amount */}
                                        <MDBox display="flex" justifyContent="space-between" flex={2}>
                                            <MDTypography
                                                sx={{
                                                    width: "33%", // Consistent width for each column
                                                    textAlign: "center",
                                                    fontSize: (theme) => theme.typography.fontSize,
                                                    color: (theme) => theme.palette.text.secondary,
                                                }}
                                            >
                                                {row.currency}
                                            </MDTypography>
                                            <MDTypography
                                                sx={{
                                                    width: "33%", // Consistent width for each column
                                                    textAlign: "center",
                                                    fontSize: (theme) => theme.typography.fontSize,
                                                    color: (theme) => theme.palette.text.secondary,
                                                }}
                                            >
                                                {row.rate}
                                            </MDTypography>
                                            <MDTypography
                                                sx={{
                                                    width: "33%", // Consistent width for each column
                                                    textAlign: "right",
                                                    fontSize: (theme) => theme.typography.fontSize,
                                                    color: (theme) => theme.palette.text.secondary,
                                                }}
                                            >
                                                {row.amount}
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                );
                            })}

                        {/* Net Amount Row */}
                        <MDBox
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            py={1}
                            mt={2}
                            px={2} // Padding for left and right
                            sx={{
                                borderTop: "2px solid #ddd",
                            }}
                        >
                            <MDTypography
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: (theme) => theme.typography.fontSize,
                                    flex: 1,
                                    color: (theme) => theme.palette.text.primary,
                                }}
                            >
                                Net Amount
                            </MDTypography>
                            <MDBox display="flex" justifyContent="space-between" flex={2}>
                                <MDTypography
                                    sx={{
                                        width: "33%", // Consistent width for each column
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        fontSize: (theme) => theme.typography.fontSize,
                                        color: (theme) => theme.palette.text.primary,
                                    }}
                                >
                                    INR
                                </MDTypography>
                                <MDTypography
                                    sx={{
                                        width: "33%", // Consistent width for each column
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        fontSize: (theme) => theme.typography.fontSize,
                                        color: (theme) => theme.palette.text.primary,
                                    }}
                                >
                                    -
                                </MDTypography>
                                <MDTypography
                                    sx={{
                                        width: "33%", // Consistent width for each column
                                        textAlign: "right",
                                        fontWeight: "bold",
                                        fontSize: (theme) => theme.typography.fontSize,
                                        color: (theme) => theme.palette.text.primary,
                                    }}
                                >
                                    {netVolume?.toFixed(2)}
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </MDBox>


                </MDBox>


                {/* USDT Conversion Summary */}
                {
                    type === "settlement" && (
                        <MDBox mt={2}>
                            <MDTypography variant="body1" fontWeight="bold" sx={{ color: "#007bff", mb: 1, fontSize: "14px" }}>
                                USDT Conversion Summary
                            </MDTypography>
                            <MDTypography variant="body2" fontWeight="medium" mb={1} sx={{ fontSize: "12px" }}>
                                <strong>USDT Rate:</strong> {usdtRate || 0} INR
                            </MDTypography>
                            <MDTypography variant="body2" fontWeight="medium" sx={{ fontSize: "12px" }}>
                                <strong>Equivalent in USDT:</strong> {(usdt || 0)?.toFixed(2)}
                            </MDTypography>
                        </MDBox>
                    )
                }


                {/* Remarks */}
                <MDBox mt={2}>
                    <MDTypography variant="body1" fontWeight="bold" sx={{ color: "#007bff", mb: 1, fontSize: "14px" }}>
                        Remarks
                    </MDTypography>
                    <MDTypography variant="body2" fontWeight="medium" sx={{ fontSize: "12px" }}>
                        {ref_no || "No remarks available."}
                    </MDTypography>
                </MDBox>

                {/* Footer with Download Invoice Button */}
                <MDBox mt={3} display="flex" justifyContent="flex-end">
                    <MDButton
                        variant="contained"
                        color="info"
                        onClick={() => downloadInvoice(settlement)}
                        size="small"
                    >
                        Download Invoice
                    </MDButton>
                </MDBox>
            </MDBox>
        </Drawer>
    );
}

SettlementDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    merchant: PropTypes.string,
    settlement: PropTypes.shape({
        type: PropTypes.string,
        transaction_date: PropTypes.string,
        txIndex: PropTypes.number,
        amount: PropTypes.number,
        netVolume: PropTypes.number,
        feePercentage: PropTypes.number,
        netFees: PropTypes.number,
        gst: PropTypes.number,
        netGst: PropTypes.number,
        bankFees: PropTypes.number,
        netBankFees: PropTypes.number,
        usdtRate: PropTypes.number,
        usdt: PropTypes.number,
        ref_no: PropTypes.string,
    }),
    downloadInvoice: PropTypes.func.isRequired,
};
