import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useState, useEffect } from 'react'
import DefaultProjectCard from 'examples/Cards/ProjectCards/DefaultProjectCard'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'
import { useMaterialUIController } from 'context'
import { useConfig } from "../../config"
import { toast } from "react-toastify"
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BeatLoader from "react-spinners/BeatLoader";
import MDTypography from 'components/MDTypography'
import SettlementLogs from "layouts/settlementV2/settlementLogs"
import formatTimeAndDate from "util/formatTimeAndDate";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import logo from "assets/images/payhub.png"
import { capitalizeFirstLetter } from 'util/formatTimeAndDate'
import Loader from 'util/Loader'
import { useSandbox } from 'ZustandState/useSandbox'
import { Formik, Form, Field } from 'formik'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { MenuItem } from '@mui/material'
function Settlement() {
    const { apiUrl } = useConfig()
    const sandbox = useSandbox((state) => state.sandbox)
    const userEmail = localStorage.getItem('user_email')
    const token = localStorage.getItem('user_token')
    const userApiKey = localStorage.getItem('user_apiKey')
    const [controller] = useMaterialUIController()

    const [allDataLoading, setAllDataLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [settlementLogs, setSettlementLogs] = useState([])
    // const [csvCompleteData, setCsvCompleteData] = useState([])
    // const [loading, setLoading] = useState(false)
    const [settlementType, setSettlementType] = useState([])
    const [logsType, setLogsType] = useState("all")
    const {
        darkMode
    } = controller
    const [data, setData] = useState()
    const initialDateRangeValues = {
        emailId: userEmail,
        start_date: '',
        end_date: ''
    }
    async function getProfile(body) {
        try {
            const response = await fetch(`${apiUrl}/user/getprofile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': token,
                    'apiKey': userApiKey || ''
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
            if (res.responseCode === 403) {
                toast.error(res?.responseMessage, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                });
                localStorage.removeItem('user_token');
                navigate('/authentication/sign-in');
            }
            if (res.responseCode !== 200) {
                //console.log('unable to get user')
                return;
            }
            if (res?.responseData) {
                setData(res?.responseData)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const getSettlementLogs = async () => {
        try {
            let body = {
                emailId: userEmail,
                type: logsType,
                limit: 10,
                skip: page * 10
            }
            if (!body.emailId) {
                return;
            }
            const response = await fetch(`${apiUrl}/user/getSettlementsByType`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'token': token,
                    'apiKey': userApiKey || ''
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setSettlementLogs(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Settlements: ", err)
        }
    }
    const getSettlementTypes = async () => {
        try {
            const response = await fetch(`${apiUrl}/user/settlementConstants`, {
                method: 'GET',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setSettlementType(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getSettlementTypes: ", err)
        }
    }
    // const getSettlementLogsForCsv = async (body) => {
    //     try {
    //         if (!body.emailId) {
    //             return;
    //         }
    //         const response = await fetch(`${apiUrl}/user/getallusersettlements`, {
    //             method: 'POST',
    //             headers: {
    //                 //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
    //                 'Content-Type': 'application/json',
    //                 'token': token,
    //                 'apiKey': userApiKey || ''
    //             },
    //             body: JSON.stringify(body)
    //         })

    //         if (!response) return;
    //         const res = await response.json()
    //         if (res.responseCode !== 200) return;
    //         return res?.responseData
    //     } catch (err) {
    //         console.log("Error Fetching Settlements: ", err)
    //     }
    // }
    // const downloadSettlementLogs = async () => {
    //     setLoading(true)
    //     setCsvCompleteData([])
    //     let csvPage = 0
    //     let responseData = true
    //     do {
    //         let body = {
    //             emailId: userEmail || '',
    //             limit: 500,
    //             skip: csvPage * 500,
    //         }

    //         await getSettlementLogsForCsv(body).then((res) => {
    //             if (res.length === 0) responseData = false;
    //             csvCompleteData.push(...res)
    //         })
    //         csvPage++
    //     } while (responseData)

    //     // Create the CSV content
    //     const csvContent = [
    //         ["Time", "Date", "Gross Volume", "Net Fees", "Bank Fees", "Net Gst", "Chargeback", "Rolling Reserve", "Misc", "Refund", "Net Volume", "USDT Rate", "USDT Net", "Remarks"],
    //         ...csvCompleteData.map(({ transaction_date, amount, feeCharged, netBankFees, netGst, chargeback, rolling_reserve, misc, refund, amountSettled, usdtRate, usdt, ref_no }) => [
    //             formatTimeAndDate(transaction_date).formattedTime || '',
    //             formatTimeAndDate(transaction_date).formattedDate || '',
    //             amount,
    //             feeCharged?.toFixed(2),
    //             netBankFees?.toFixed(2),
    //             netGst?.toFixed(2),
    //             chargeback,
    //             rolling_reserve,
    //             misc,
    //             refund,
    //             amountSettled?.toFixed(2),
    //             usdtRate,
    //             usdt?.toFixed(2),
    //             ref_no || '',
    //         ]),
    //     ].map(row => row.join(',')).join('\n');

    //     // Create a Blob containing the CSV data
    //     const blob = new Blob([csvContent], { type: 'text/csv' });

    //     // Create a download link
    //     const url = URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'settlement.csv';

    //     // Programmatically click the link to trigger the download
    //     a.click();

    //     // Clean up
    //     URL.revokeObjectURL(url);
    //     setLoading(false)
    // }
    const handleTypeDateRangeSubmit = async (values, { setSubmitting }) => {
        try {
            if (new Date(values.end_date) < new Date(values.start_date)) {
                toast.error("Invalid Date Range", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 2 seconds
                    hideProgressBar: false,
                });
                return;
            }
            values.type = logsType;
            const response = await fetch(`${apiUrl}/user/downloadSettlementCsvByDateAndType`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                    'token': token,
                    'apiKey': userApiKey || ''
                },
                body: JSON.stringify(values)
            })
            const res = await response.json()
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            const a = document.createElement('a');
            a.href = res?.responseData;
            a.download = 'settlement.csv';
            a.click(); // Programmatically click the link to trigger the download
            URL.revokeObjectURL(res?.responseData);  // Clean up
        } catch (err) {
            console.log("Error Fetching handleTypeDateRangeSubmit: ", err)
        }
    }
    const createPDF = async (log) => {
        const pdfDoc = await PDFDocument.create();

        // Add a new page with full A4 dimensions
        const page = pdfDoc.addPage([595, 842]);

        // Load fonts
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        // Embed logo image
        const logoUrl = logo; // Assuming `logo` is defined elsewhere with the actual URL or path to the logo
        const logoImageBytes = await fetch(logoUrl).then((res) => res.arrayBuffer());
        const logoImage = await pdfDoc.embedPng(logoImageBytes);

        // Draw the Payhub logo at the top
        page.drawImage(logoImage, {
            x: 50,
            y: 760,
            width: 140,
            height: 30,
        });

        // Header - New title for the report
        page.drawText(`Transaction Settlement Report`, {
            x: 50,
            y: 720,
            size: 32,
            font: boldFont,
            color: rgb(0, 0, 0),
        });

        // Draw the separator line below the title
        page.drawLine({
            start: { x: 50, y: 710 },
            end: { x: 545, y: 710 },
            thickness: 2,
            color: rgb(0.1, 0.2, 0.7),
        });

        // Add spacing before the intro text
        let yPosition = 690;

        // Greeting and introduction text
        page.drawText('Hello from Payhub!', { x: 50, y: yPosition, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        yPosition -= 15;
        page.drawText('Your transaction report is ready!', { x: 50, y: yPosition, size: 12, font: regularFont });
        yPosition -= 15;
        page.drawText('The details of your transaction are enclosed for your reference.', { x: 50, y: yPosition, size: 12, font: regularFont });

        // Add spacing before Merchant, Settlement Date, Type of Report, and Settlement No.
        yPosition -= 30;
        page.drawText(`Merchant - ${capitalizeFirstLetter(data?.business_name)}`, {
            x: 50,
            y: yPosition,
            size: 12,
            font: regularFont,
            color: rgb(0, 0, 0),
        });
        yPosition -= 15;
        page.drawText(`Settlement Date - ${log.transaction_date.split('T')[0]}`, {
            x: 50,
            y: yPosition,
            size: 12,
            font: regularFont,
        });
        yPosition -= 15;
        page.drawText(`Type of Report - ${log?.type === "rolling_reserve" ? "Rolling Reserve" : capitalizeFirstLetter(log?.type) || 'N/A'}`, {
            x: 50,
            y: yPosition,
            size: 12,
            font: regularFont,
            color: rgb(0, 0, 0),
        });
        yPosition -= 15;
        page.drawText(`Settlement No. - ${(log.txIndex + 1)}`, {
            x: 50,
            y: yPosition,
            size: 12,
            font: regularFont,
        });

        // Add spacing before the table headers
        yPosition -= 30;

        // Table header
        const currencyColumnX = 300; // Shifted to the right
        const rateColumnRightBoundary = 430; // Shifted to the right
        const amountColumnRightBoundary = 545;

        page.drawText('Particulars', { x: 50, y: yPosition, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Currency', { x: currencyColumnX, y: yPosition, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Rate (%)', { x: rateColumnRightBoundary - 40, y: yPosition, size: 12, font: boldFont, color: rgb(0, 0, 0) });
        page.drawText('Amount', { x: amountColumnRightBoundary - 50, y: yPosition, size: 12, font: boldFont, color: rgb(0, 0, 0) });

        // Draw line below header
        yPosition -= 5;
        page.drawLine({
            start: { x: 50, y: yPosition },
            end: { x: 545, y: yPosition },
            thickness: 1,
            color: rgb(0.1, 0.2, 0.7),
        });

        yPosition -= 15;

        // Table data
        const tableData = [
            { particulars: 'Total Amount', currency: 'INR', amount: log.amount.toFixed(2).toString(), rate: '' },
            { particulars: 'Platform Fee', currency: 'INR', amount: log?.netFees?.toFixed(2).toString() || '0.00', rate: log?.feePercentage?.toString() || '' },
            { particulars: 'Bank Fee', currency: 'INR', amount: log?.netBankFees?.toFixed(2).toString() || '0.00', rate: log?.bankFees?.toString() || '' },
            { particulars: 'GST', currency: 'INR', amount: log?.netGst?.toFixed(2).toString() || '0.00', rate: log?.gst?.toString() || '' },
            { separator: true }, // Always include the separator before Net Amount
            { particulars: 'Net Amount', currency: 'INR', amount: log?.netVolume?.toFixed(2).toString() || '0.00', rate: '' },
        ];

        // Filter rows based on type
        const filteredTableData = tableData.filter((row, index) => {
            if (row.separator || row.particulars === 'Net Amount') return true; // Always include separator and Net Amount
            if (log.type === 'settlement') return index <= 3; // Show Total Amount, Platform Fee, Bank Fees, and GST
            if (['refund', 'chargeback'].includes(log.type)) return index <= 1; // Show Total Amount, Platform Fee
            if (['misc', 'rolling_reserve'].includes(log.type)) return index === 0; // Show only Total Amount
            return false; // Exclude other rows
        });

        // Render table rows
        filteredTableData.forEach((item) => {
            if (item.separator) {
                yPosition -= 10;
                page.drawLine({
                    start: { x: 50, y: yPosition },
                    end: { x: 545, y: yPosition },
                    thickness: 1,
                    color: rgb(0.1, 0.2, 0.7),
                });
                yPosition -= 20;
                return;
            }

            // Align Particulars to the left
            page.drawText(item.particulars, { x: 50, y: yPosition, size: 12, font: regularFont, color: rgb(0, 0, 0) });

            // Align other columns to the right
            page.drawText(item.currency, { x: currencyColumnX, y: yPosition, size: 12, font: regularFont, color: rgb(0, 0, 0) });

            const rateTextWidth = regularFont.widthOfTextAtSize(item.rate, 12);
            page.drawText(item.rate, {
                x: rateColumnRightBoundary - rateTextWidth,
                y: yPosition,
                size: 12,
                font: regularFont,
            });

            const amountTextWidth = regularFont.widthOfTextAtSize(item.amount, 12);
            page.drawText(item.amount, {
                x: amountColumnRightBoundary - amountTextWidth,
                y: yPosition,
                size: 12,
                font: regularFont,
            });

            yPosition -= 20;
        });

        // USDT Conversion Summary (conditionally displayed)
        if (log?.type === 'settlement') {
            yPosition -= 30;
            page.drawText('USDT Conversion Summary', { x: 50, y: yPosition, size: 14, font: boldFont, color: rgb(0, 0, 1) });
            yPosition -= 20;
            page.drawText(`USDT Rate: ${log?.usdtRate?.toString()} INR`, { x: 50, y: yPosition, size: 12, font: regularFont });
            yPosition -= 20;
            page.drawText(`Equivalent in USDT: ${log?.usdt?.toFixed(2) || 'N/A'}`, { x: 50, y: yPosition, size: 12, font: regularFont });
        }

        // Remarks Section
        yPosition -= 30;
        page.drawText('Remarks', { x: 50, y: yPosition, size: 14, font: boldFont, color: rgb(0, 0, 1) });
        yPosition -= 20;
        page.drawText(log?.ref_no || 'No remarks provided.', { x: 50, y: yPosition, size: 12, font: regularFont });

        // Footer Section
        yPosition -= 30;
        page.drawLine({
            start: { x: 50, y: yPosition + 10 },
            end: { x: 545, y: yPosition + 10 },
            thickness: 1,
            color: rgb(0.1, 0.2, 0.7),
        });

        yPosition -= 20;
        page.drawText('Got questions? FAQ or get in touch with one of our experts here.', { x: 50, y: yPosition, size: 10, font: regularFont, color: rgb(0, 0, 1) });
        yPosition -= 15;
        page.drawText('Thank you!\nTeam Payhub', { x: 50, y: yPosition - 20, size: 10, font: regularFont, color: rgb(0, 0, 0) });

        // Save and download the PDF
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `invoice,${capitalizeFirstLetter(data?.business_name)} ${formatTimeAndDate(log.transaction_date).formattedDate}.pdf`;
        link.click();
    };
    useEffect(() => {
        let body = {
            emailId: userEmail
        }
        getProfile(body)
        getSettlementTypes()
    }, [sandbox])
    useEffect(() => {
        getSettlementLogs().then(() => {
            setAllDataLoading(false)
        })
    }, [page, sandbox, logsType])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                allDataLoading ? (<Loader />) : (
                    <MDBox py={3}>
                        <MDBox mt={1}>
                            {
                                data && <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                    <Grid xl={5}>
                                        <DefaultProjectCard
                                            business={data?.business_name}
                                            email={data?.emailId}
                                            name={`${data?.first_name || ''} ${data?.last_name || ''}`}
                                        />
                                    </Grid>
                                    <Grid xl={7}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} lg={3.5} xl={3.5}>
                                                <MDBox mb={1.5}>
                                                    <ComplexStatisticsCard
                                                        color="7FDF9A"
                                                        title="Today's Volume"
                                                        count={Number(data?.last24hr).toFixed(0) || ''}
                                                    />
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} lg={3.5} xl={3.5}>
                                                <MDBox mb={1.5}>
                                                    <ComplexStatisticsCard
                                                        color="FFFED2"
                                                        title="Yesterday's Volume"
                                                        count={`${Number(data?.yesterday).toFixed(0) || ''}`}
                                                    />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} lg={3.5} xl={3.5}>
                                                <MDBox mb={1.5}>
                                                    <ComplexStatisticsCard
                                                        color="C9DEDE"
                                                        title="Wallet Balance"
                                                        count={`${Number(data.balance).toFixed(0) || 0}`}
                                                    />
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {/* settlementLogs */}
                            <MDBox mt={5} mx={0.5} ml={3}>
                                <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                    <Grid container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" py={1.5}>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <MDBox display="flex" flexDirection="row" alignItems="center">
                                                <MDTypography variant="h6" color={darkMode ? "white" : "black"}>Settlement Logs</MDTypography>
                                                {/* <MDBox pl={3}>
                                                    {
                                                        loading ? (<BeatLoader
                                                            color="#36d7b7"
                                                            cssOverride={{}}
                                                            size={10}
                                                        />) : (
                                                            <FileDownloadOutlinedIcon onClick={() => downloadSettlementLogs()} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />
                                                        )
                                                    }
                                                </MDBox> */}
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={7} xl={7} px={2}>
                                            <Formik initialValues={initialDateRangeValues} onSubmit={handleTypeDateRangeSubmit} >
                                                {({ handleChange, values, isSubmitting }) => (
                                                    <Form>
                                                        <Grid container spacing={1} alignItems="center">
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Field
                                                                    type="text"
                                                                    select
                                                                    label="Select Settlement type"
                                                                    size="large"
                                                                    as={MDInput}
                                                                    fullWidth
                                                                    value={logsType}
                                                                    onChange={(e) => {
                                                                        e.target.value !== "" && setLogsType(e.target.value); setPage(0)
                                                                    }}
                                                                    InputProps={{
                                                                        classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                                    }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                >
                                                                    <MenuItem value="all">All</MenuItem>
                                                                    {settlementType && settlementType.map((item, index) => (
                                                                        <MenuItem key={index} value={item}>
                                                                            {item === "rolling_reserve" ? "Rolling Reserve" : capitalizeFirstLetter(item)}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Field>
                                                            </Grid>
                                                            {/* Start Date Input */}
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Field
                                                                    as={MDInput}
                                                                    label="Start Date"
                                                                    size="large"
                                                                    fullWidth
                                                                    type="date"
                                                                    name="start_date"
                                                                    onChange={handleChange}
                                                                    value={values.start_date}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>

                                                            {/* End Date Input */}
                                                            <Grid item xs={12} sm={6} md={3}>
                                                                <Field
                                                                    as={MDInput}
                                                                    label="End Date"
                                                                    size="large"
                                                                    fullWidth
                                                                    type="date"
                                                                    name="end_date"
                                                                    onChange={handleChange}
                                                                    value={values.end_date}
                                                                    variant="outlined"
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>

                                                            {/* Download Button */}
                                                            <Grid item xs={12} sm={12} md={3} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
                                                                <MDButton
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color={isSubmitting ? "secondary" : "info"} // Change color when submitting
                                                                    fullWidth
                                                                    sx={{
                                                                        height: "48px",
                                                                        // minWidth: "150px",
                                                                        opacity: isSubmitting ? 0.5 : 1, // Fade button when submitting
                                                                        cursor: isSubmitting ? "not-allowed" : "pointer", // Show disabled cursor
                                                                    }}
                                                                    disabled={isSubmitting} // Disable button
                                                                >
                                                                    {isSubmitting ? "Downloading..." : "Download CSV"} {/* Change text */}
                                                                </MDButton>
                                                            </Grid>

                                                        </Grid>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Grid>
                                    </Grid>

                                    <SettlementLogs pages={page} pageSetter={setPage} logs={settlementLogs} downloadInvoice={createPDF} merchant={data?.business_name} />
                                </Grid>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                )
            }

        </DashboardLayout>
    )
}

export default Settlement